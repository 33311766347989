import { Subscriber } from "../abstract/Subscriber/Subscriber.ts";
import { templatesAxios } from "./TemplatesAxiosInstance.ts";
import { UserService } from "../UserService/UserService.ts";
import { projectsService } from "../../machines/projects/projects.instance.ts";

import { ISubscriber } from "../abstract/Subscriber/Subscriber.types.ts";
import { ActiveProject, Project, ProjectHistory } from "../../types/types.ts";
import { Page } from "../../types/types.ts";

export type Route = {
  frameId: string;
  name: string;
  slug: string;
};

export class Templates extends Subscriber<Project[]> {
  state = [] as Project[];
  //store scripts like resetCss to add to head when project is loaded/page changed
  scripts = [] as string[];
  // store js scripts to add this between change pages
  jsScripts = [] as string[];

  async getTemplates() {
    const { data } = await templatesAxios.get<Project[]>("/api/project-templates/meta-data", {});
    this.notifySubscribers();
    return data;
  }

  setActiveProject = (projectId: string) => {
    this.state = this.state.map((project) => ({ ...project, active: project.projectId === projectId }));
  };

  getActiveProject = () => this.state.find((project) => project.active);
  sendStateToSubscriber = (sb: ISubscriber<Project[]>) => sb([...this.state]);
  transformProjectId = (projectId: string) => projectId.replace("/", "+");
  getActiveProjectId = () => {
    return this.getActiveProject()?.projectId || "no active project";
  };

  getPagesByProjectId = async (projectId: string) => {
    const { data } = await templatesAxios.get<{ pages: Page[]; reset: string; variables: string; components: string }>(
      `/api/project-templates/figma-data/${this.transformProjectId(projectId)}`
    );
    const { pages, reset, variables, components } = data;

    return pages.map((page, idx) => {
      if (idx === 0) {
        return {
          ...page,
          styles: `${reset ?? ""} ${components ?? ""} ${variables ?? ""} ${page.styles}`,
        };
      }

      return page;
    });
  };

  duplicateTemplate = async (templateId: string) => {
    try {
      const userFigmaId = UserService.getInstance().getData()?.FIGMA_ID;
      const { data } = await templatesAxios.post<Project>(`/api/project-templates/clone`, {
        templateId,
        userId: userFigmaId,
      });
      // push data for to project service
      projectsService.state.push(data);
    } catch (error) {
      console.error(error);
    }
    projectsService.notifySubscribers();
    // this.notifySubscribers();
  };

  getHistory = async (projectId: string) => {};
  deleteProject = async (projectId: string) => {};
  updateActiveProjectPages = async (pages: ActiveProject["pages"]) => {};
  projectFetched = async (projectId: string) => {};
  getOvhPagesByProjectId = async (projectId: string) => {};
  getProjectRouting = async (projectId: string) => {};
  saveHistory = async (projectId: string, data: ProjectHistory) => {};
  publishProject = async (projectData: { projectId: string; customDomain?: string }) => {};
  unpublishProject = async (projectData: { projectId: string; customDomain?: string }) => {};
  updateProject = (project: Project) => {};
  getProjectsWithPublishedCustomDomain = () => {};
  addPage = async (projectId: string, page: { name: string; frameId: string }) => {};
  renamePage = async (projectId: string, pageId: string, name: string) => {};
  deletePage = async (projectId: string, pageId: string, newMainPageId?: string | null) => {};
  checkCustomDomainHealth = async () => {};
  invalidateDomain = async (projectId: string) => {};
  setSynced = async (projectId: string, state: boolean) => {};
  checkSubdomainAvailability = async (value: string, signal: AbortSignal) => {};
  addPageRouting = async (projectId: string, route: Route) => {};
  removePageRouting = async (projectId: string, route: Route) => {};
  updatePageRouting = async (projectId: string, route: Route) => {};
  downgradeProjectSubscription = async (projectId: string, newPriceId: string) => {};
  updateActiveProjectSubscriptionData = (projectId: string, updatedSubscription: Project["subscription"]) => {};
  downloadActiveProject = async () => {};
  updateProjectData = async (
    projectId: string,
    updatedProjectData: {
      customDomain?: string;
      subdomain?: string;
      name?: string;
      description?: string;
      customHead?: string;
      customCode?: string;
    }
  ) => {};
}
