import { useEffect, useState } from "react";
import ColorPicker, { ColorObject } from "react-pick-color";

import { colorPickerServiceInstance } from "./ColorPicker.service";

import useOutsideClick from "../../../../hooks/useOutsideClick/useOutsideClick";

import "./ColorPicker.css";

export const ColorPickerModal = () => {
  const [colorPickerState, subscribeColorPickerService] = useState(colorPickerServiceInstance.state);
  const [localPosition, setLocalPosition] = useState({ x: 0, y: 0 });
  // flag to show color picker only after position is calculated - remove flickering
  const [isPositionCalculated, setIsPositionCalculated] = useState(false);
  const ref = useOutsideClick<HTMLDivElement>(() => {
    colorPickerServiceInstance.closePicker();
    setIsPositionCalculated(false);
  });

  useEffect(() => {
    colorPickerServiceInstance.subscribe(subscribeColorPickerService);
    return () => {
      colorPickerServiceInstance.state.isOpen = false;
      colorPickerServiceInstance.unsubscribe(subscribeColorPickerService);
    };
  }, []);
  // adjust position if color picker is out of screen
  useEffect(() => {
    if (ref.current) {
      const position = ref.current.getBoundingClientRect();
      const { innerHeight } = window;

      const isBelowWindow = colorPickerState?.position.y + position.height > innerHeight;

      const calculatedYPosition = isBelowWindow
        ? colorPickerState.position.y - (colorPickerState?.position.y + position.height - innerHeight + 16)
        : colorPickerState.position.y;

      let calculatedXPosition;

      if (colorPickerState.side === "right") {
        calculatedXPosition =
          colorPickerState.position.x + position.width > window.innerWidth
            ? colorPickerState.position.x - position.width - 32
            : colorPickerState.position.x;
      } else {
        calculatedXPosition = colorPickerState.position.x - position.width - 32;
      }

      if (calculatedYPosition !== localPosition.y) {
        setLocalPosition({
          x: calculatedXPosition,
          y: calculatedYPosition,
        });
      }
      setIsPositionCalculated(true);
    }
  }, [colorPickerState?.position.y, ref.current]);

  const handleChange = (color: ColorObject) => {
    if (colorPickerState.onChange !== null) {
      colorPickerState.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
    }
  };

  if (!colorPickerState.isOpen) return null;

  return (
    <div
      className="color-picker-modal"
      role="dialog"
      style={{
        top: localPosition.y,
        left: localPosition.x,
        opacity: isPositionCalculated ? 1 : 0,
      }}
      ref={ref}
    >
      <ColorPicker color={colorPickerState.color} onChange={handleChange} theme={{}} />
    </div>
  );
};
