import { useRef, useState } from "react";

import { styleManagerService } from "../../StylesManager.service";

import { PropertyLabel } from "../PropertyLabel";
import { StackLayer } from "../StackLayer";
import { StackProperties } from "../StackProperties";
import { Add } from "../../../../../../../assets/svg/Add";

import { TStackProperty } from "./StackProperty.types";

import "./StackProperty.css";

export const StackProperty: TStackProperty = ({ property, allVariables }) => {
  const [selectedLayer, setSelectedLayer] = useState<any>(property.getSelectedLayer());
  const layerRef = useRef<HTMLDivElement>(null);
  const layers = property.getLayers();
  const hasLayers = property.hasLayers();

  const getStyleForModal = () => {
    if (!layerRef.current) return {};
    const { top, left } = layerRef.current.getBoundingClientRect();

    return {
      top: `${top}px`,
      left: `${left - 256}px`,
    };
  };

  return (
    <div className="stack-property">
      <div className={`stack-property__header${hasLayers ? "" : " stack-property__header--empty"}`}>
        <PropertyLabel
          text={property.getLabel()}
          modifier={property.canClear() ? "edited" : "primary"}
          clearValue={property.canClear() ? () => property.clear() : undefined}
        />
        <button
          className="stack-property__add"
          onClick={() => {
            property.addLayer();
            setSelectedLayer(property.getSelectedLayer());
            styleManagerService.notifySubscribers();
          }}
        >
          <Add />
        </button>
      </div>
      {hasLayers && (
        <div className="stack-property__layers" style={{ gridColumn: "1/-1" }} ref={layerRef}>
          {layers.map((layer) => {
            return (
              <StackLayer
                layer={layer}
                property={property}
                setSelectedLayer={setSelectedLayer}
                selectedLayer={selectedLayer}
                key={layer.getId()}
              />
            );
          })}
        </div>
      )}
      {selectedLayer && (
        <StackProperties
          property={property}
          allVariables={allVariables}
          handleClose={() => setSelectedLayer(null)}
          styles={getStyleForModal()}
        />
      )}
    </div>
  );
};
