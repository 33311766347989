import { useState } from "react";
import { TraitManager } from "../trait-manager/TraitManager.tsx";
import { StylesManager } from "../styles-manager/StylesManager.tsx";
// import { ColorVariables } from "../../../../AlphaO/molecules/ColorVariables/ColorVariables.tsx";
// import { FontVariables } from "../../../../AlphaO/molecules/FontVariables/FontVariables.tsx";

export const isActive = (view: number, currentView: number) =>
  view === currentView ? " right-sidebar__tab--active " : "";

export const RightSideBar = () => {
  const [view, setView] = useState(0);

  return (
    <div className="main-section__right-sidebar right-sidebar">
      <div className="right-sidebar__tabs">
        <button className={`right-sidebar__tab` + isActive(0, view)} onClick={() => setView(0)}>
          Styles
        </button>
        <button className={`right-sidebar__tab` + isActive(1, view)} onClick={() => setView(1)}>
          Properties
        </button>
      </div>
      {/* <div style={{ maxWidth: "100%", overflow: "hidden" }}>
              <div id="selector-manager">{selectorManagerInstance.state.open && <SelectorManagerMenu />}</div>
            </div> */}
      <div
        style={{
          display: isActive(0, view) ? "block" : "none",
        }}
        className="styles-wrapper"
      >
        <StylesManager />
      </div>
      {isActive(1, view) && <TraitManager />}
    </div>
  );
};
