import { type CSSProperties } from "react";

type TDashboard = {
  className?: string;
  style?: CSSProperties;
};

export const Dashboard = ({ className, style }: TDashboard) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.6665 17.0013V12.168C11.6665 11.8918 11.8903 11.668 12.1665 11.668H16.9998C17.276 11.668 17.4998 11.8918 17.4998 12.168V17.0013C17.4998 17.2775 17.276 17.5013 16.9998 17.5013H12.1665C11.8903 17.5013 11.6665 17.2775 11.6665 17.0013Z"
      fill="#FF4900"
      stroke="#FF4900"
      strokeWidth="1.25"
    />
    <path
      d="M2.5 17.0013V12.168C2.5 11.8918 2.72386 11.668 3 11.668H7.83333C8.10947 11.668 8.33333 11.8918 8.33333 12.168V17.0013C8.33333 17.2775 8.10947 17.5013 7.83333 17.5013H3C2.72386 17.5013 2.5 17.2775 2.5 17.0013Z"
      stroke="#D4D4D8"
      strokeWidth="1.25"
    />
    <path
      d="M11.6664 7.83349V3.00015C11.6664 2.72401 11.8903 2.50015 12.1664 2.50015H16.9998C17.2759 2.50015 17.4998 2.72401 17.4998 3.00015V7.83349C17.4998 8.10963 17.2759 8.33349 16.9998 8.33349H12.1664C11.8903 8.33349 11.6664 8.10963 11.6664 7.83349Z"
      stroke="#D4D4D8"
      strokeWidth="1.25"
    />
    <path
      d="M2.5 7.83349V3.00015C2.5 2.72401 2.72386 2.50015 3 2.50015H7.83333C8.10947 2.50015 8.33333 2.72401 8.33333 3.00015V7.83349C8.33333 8.10963 8.10947 8.33349 7.83333 8.33349H3C2.72386 8.33349 2.5 8.10963 2.5 7.83349Z"
      stroke="#D4D4D8"
      strokeWidth="1.25"
    />
  </svg>
);
