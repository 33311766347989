import { Login } from "../components/pages/Login/Login";
import { VerifyPage } from "../components/pages/VerifyPage/VerifyPage";
import { CreateAccount } from "../components/pages/CreateAccount/CreateAccount";
import { MobileBrickwall } from "../components/pages/MobileBrickwall/MobileBrickwall";
import { Alpha_O } from "../components/pages/Alpha_O";
import { MyProjects } from "../components/pages/MyProjects";
import { Pricing } from "../components/pages/Pricing";
import { Community } from "../components/pages/Community";
import { ProjectSettings } from "../components/pages/ProjectSettings";
import { TemplatesDashboards } from "../components/pages/TemplatesDashboard";
import { Alpha_O_templates } from "../components/pages/Alpha_O_templates/Alpha_O_templates";

export const LoadingPage = {
  path: "/loading",
  component: <VerifyPage />,
};

export const CreateAccountPage = {
  path: "/createAccount",
  component: <CreateAccount />,
};

// export const UserPlanAndBilling = {
//   path: "/plan-and-billing",
//   component: (
//     <MeLayout title="Plan & Billing">
//       <PlanAndBillingPage />
//     </MeLayout>
//   ),
// };

export const UserPlanAndBilling = {
  path: "/pricing",
  component: <Pricing />,
};

export const LoginPage = {
  path: "/login",
  component: <Login />,
};

export const LogoutPage = {
  path: "/login",
  component: (
    <Login
      toastMsg={
        <div>
          <h2>Your session has expired.</h2>
          <p>Please log in again.</p>
        </div>
      }
    />
  ),
};

export const FigmaPluginLoginPage = {
  path: "/login/figma-plugin",
  component: <Login />,
};

export const MobileBrickWall = {
  path: "/mobile",
  component: <MobileBrickwall />,
};

export const AlphaOPage = {
  path: "/alpha_o",
  component: <Alpha_O />,
};

export const AlphaOPageTemplates = {
  path: "/alpha_o_templates",
  component: <Alpha_O_templates />,
};

// export const ProjectsPage = {
//   path: "/projects",
//   component: (
//     <DashboardAlphaO isAlwaysOpen>
//       <Projects />
//     </DashboardAlphaO>
//   ),
// };

export const ProjectsPage = {
  path: "/projects",
  component: <MyProjects />,
};

export const TemplatesPage = {
  path: "/templates",
  component: <TemplatesDashboards />,
};

export const CommunityPage = {
  path: "/community",
  component: <Community />,
};

export const ProjectSettingsPage = {
  path: "/project/settings",
  component: <ProjectSettings />,
};
