import { createMachine } from "xstate";
import { StyleManagerService } from "./StylesManager.service.ts";

export const stylesManagerMachine = (styleManagerService: StyleManagerService) => {
  return createMachine(
    {
      id: "stylesManager",
      initial: "START",
      context: {
        service: styleManagerService,
      },

      states: {
        START: {
          on: {
            INIT: "INIT",
          },
        },
        INIT: {
          invoke: {
            src: "init",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        READY: {
          entry: ["notify"],
          on: {
            SELECT_COMPONENT: "SELECT_COMPONENT",
            ERROR: "ERROR",
          },
        },

        SELECT_COMPONENT: {
          invoke: {
            src: "selectComponent",
            onDone: {
              target: "READY",
            },
            onError: {
              target: "ERROR",
            },
          },
        },

        ERROR: {
          entry: () => {
            console.log("Error");
          },
        },
      },
    },
    {
      services: {
        selectComponent: async ({ service }, event) => {
          service.setComponent(event.component, event.sectors);
          return Promise.resolve();
        },

        init: async ({ service }) => {
          // TODO maybe i need do something here
          return Promise.resolve();
        },
      },
      actions: {
        notify: ({ service }) => {
          service.notifySubscribers();
        },
      },
      guards: {
        // shouldOpen: ({ service }) => {
        //   return !service.state.open;
        // },
      },
    }
  );
};
