import { createMachine } from "xstate";

import { UserService } from "../../services/UserService/UserService";
import { getWindow } from "../../utils/getWindow/getWindow";
import { Figma } from "../../services/Figma/Figma";
import { routerInstance } from "../router/router.instance";
import { dashboardInstance } from "../dashboard/dashboard.instance";

const authMachine = createMachine(
  {
    predictableActionArguments: true,
    id: "Auth",
    initial: "START",
    states: {
      START: {
        invoke: {
          src: "isUserAuthenticated",
          onDone: {
            target: "AUTHENTICATED",
          },
          onError: {
            target: "UNAUTHENTICATED",
          },
        },
        on: {
          OAUTH: {
            target: "OAUTH",
          },
        },
      },

      OAUTH: {
        invoke: {
          src: "verifyToken",
          onDone: {
            target: "AUTHENTICATED",
          },
          onError: {
            target: "UNAUTHENTICATED",
          },
        },
      },

      AUTHENTICATED: {
        entry: "sendAuthenticatedSignal",
        on: {
          LOGOUT: {
            target: "UNAUTHENTICATED",
            actions: "logout",
          },
          SESSION_EXPIRED: {
            target: "SESSION_EXPIRED",
          },
        },
      },

      UNAUTHENTICATED: {
        invoke: {
          src: "isFigmaPluginFlow",
          onDone: {
            actions: "sendFigmaPluginSignal",
          },
          onError: {
            actions: "sendLoginSignal",
          },
        },
      },

      SESSION_EXPIRED: {
        entry: ["triggerDashboardLogout", "logout"],
        always: {
          target: "UNAUTHENTICATED",
        },
      },
    },
  },
  {
    services: {
      isFigmaPluginFlow: async () =>
        new Promise<void>((resolve, reject) => {
          const figmaFlow = localStorage.getItem("figmaFlow");
          const isFigmaFlow = figmaFlow === "true";

          if (isFigmaFlow) return resolve();
          return reject();
        }),
      verifyToken: async () =>
        new Promise<void>(async (resolve, reject) => {
          const { searchParams } = new URL(getWindow().location.href);
          const code = searchParams.get("code");

          if (!code) return reject();

          try {
            await Figma.getAccessToken(code);
            //we have our cookie with jwt now
            // userService.saveStorage(data);
            // return resolve();
          } catch (error) {
            console.error("Figma.getAccessToken");
            //when no access token
          }

          try {
            const userService = UserService.getInstance();
            const isValid = await userService.isTokenValid();

            //We check if JWT is still valid
            // JWT should be in httpOnly cookie
            // Cookie is set by server and has name JWT
            if (isValid) {
              return resolve();
            } else {
              return reject();
            }
          } catch (error) {
            return reject();
          }
        }),
      //TESTED
      isUserAuthenticated: async () =>
        new Promise<void>(async (resolve, reject) => {
          try {
            await UserService.getInstance().check();
            return resolve();
          } catch (error) {
            console.log("isUserAuthenticated", error);
            return reject();
          }
        }),
    },
    actions: {
      //TESTED
      logout: () => {
        UserService.getInstance().logout();
      },
      triggerDashboardLogout: () => {
        dashboardInstance.send("LOGOUT");
      },
      sendAuthenticatedSignal: () => {
        dashboardInstance.send("AUTHENTICATED");
      },
      sendFigmaPluginSignal: () => routerInstance.send("FIGMA_PLUGIN"),
      sendLoginSignal: () => routerInstance.send("LOGIN"),
    },
  }
);

export { authMachine };
