import { colorPickerServiceInstance } from "../../../../../../AlphaO/atoms/ColorPicker/ColorPicker.service";
import { styleManagerService } from "../../StylesManager.service";

import { NumberProperty } from "../NumberProperty";
import { ColorProperty } from "../ColorProperty/ColorProperty";
import { SelectProperty } from "../SelectProperty";
import { RadioProperty } from "../RadioProperty";
import { Button } from "../../../../../../AlphaO/atoms/Button";
import { Close } from "../../../../../../../../assets/svg/close";

import useOutsideClick from "../../../../../../../hooks/useOutsideClick/useOutsideClick";

import { TStackProperties } from "./StackProperties.types";

import "./StackProperties.css";

export const StackProperties: TStackProperties = ({ property, allVariables, handleClose, styles }) => {
  const optionsRef = useOutsideClick<HTMLDivElement>(() => {
    const isDropdownOpen = styleManagerService.isOpen();
    const isColorPickerOpen = colorPickerServiceInstance.isOpen();

    if (isDropdownOpen || isColorPickerOpen) return;
    handleClose();
  });

  return (
    <div className="properties-popup" style={styles} ref={optionsRef}>
      <header className="properties-popup__header">
        <h3>{property.getLabel()}</h3>
        <Button
          type="icon"
          iconStart={<Close />}
          onClick={() => {
            handleClose();
          }}
        />
      </header>
      <div className="properties-popup__content">
        {property.getProperties().map((prop) => {
          switch (prop.get("type")) {
            case "number":
              return <NumberProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "color":
              return <ColorProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "select":
              return <SelectProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
            case "radio":
              return <RadioProperty property={prop} key={prop.getId()} />;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};
