import { ReactNode } from "react";

import { AlignCenter } from "../../../../../../assets/svg/AlignCenter";
import { AlignContentStretch } from "../../../../../../assets/svg/AlignContentStretch";
import { AlignEnd } from "../../../../../../assets/svg/AlignEnd";
import { AlignStart } from "../../../../../../assets/svg/AlignStart";
import { AlignStretch } from "../../../../../../assets/svg/AlignStretch";
import { Auto } from "../../../../../../assets/svg/Auto";
import { BorderBottomWidth } from "../../../../../../assets/svg/BorderBottomWidth";
import { BorderLeftWidth } from "../../../../../../assets/svg/BorderLeftWidth";
import { BorderRightWidth } from "../../../../../../assets/svg/BorderRightWidth";
import { BorderTopWidth } from "../../../../../../assets/svg/BorderTopWidth";
import { CornerBottomLeft } from "../../../../../../assets/svg/CornerBottomLeft";
import { CornerBottomRight } from "../../../../../../assets/svg/CornerBottomRight";
import { CornerTopLeft } from "../../../../../../assets/svg/CornerTopLeft";
import { CornerTopRight } from "../../../../../../assets/svg/CornerTopRight";
import { DirectionColumn } from "../../../../../../assets/svg/DirectionColumn";
import { DirectionColumnReverse } from "../../../../../../assets/svg/DirectionColumnReverse";
import { DirectionRow } from "../../../../../../assets/svg/DirectionRow";
import { DirectionRowReverse } from "../../../../../../assets/svg/DirectionRowReverse";
import { JustifyAround } from "../../../../../../assets/svg/JustifyAround";
import { JustifyBetween } from "../../../../../../assets/svg/JustifyBetween";
import { JustifyCenter } from "../../../../../../assets/svg/JustifyCenter";
import { JustifyEnd } from "../../../../../../assets/svg/JustifyEnd";
import { JustifyEvenly } from "../../../../../../assets/svg/JustifyEvenly";
import { JustifyStart } from "../../../../../../assets/svg/JustifyStart";
import { SpaceBottom } from "../../../../../../assets/svg/SpaceBottom";
import { SpaceTop } from "../../../../../../assets/svg/SpaceTop";
import { SpaceLeft } from "../../../../../../assets/svg/SpaceLeft";
import { SpaceRight } from "../../../../../../assets/svg/SpaceRight";

export const propertyIconMap: Record<string, ReactNode> = {
  AlignCenter: <AlignCenter />,
  AlignContentStretch: <AlignContentStretch />,
  AlignEnd: <AlignEnd />,
  AlignStart: <AlignStart />,
  AlignStretch: <AlignStretch />,
  Auto: <Auto />,
  BorderBottomWidth: <BorderBottomWidth />,
  BorderLeftWidth: <BorderLeftWidth />,
  BorderRightWidth: <BorderRightWidth />,
  BorderTopWidth: <BorderTopWidth />,
  CornerBottomLeft: <CornerBottomLeft />,
  CornerBottomRight: <CornerBottomRight />,
  CornerTopLeft: <CornerTopLeft />,
  CornerTopRight: <CornerTopRight />,
  DirectionColumn: <DirectionColumn />,
  DirectionColumnReverse: <DirectionColumnReverse />,
  DirectionRow: <DirectionRow />,
  DirectionRowReverse: <DirectionRowReverse />,
  JustifyAround: <JustifyAround />,
  JustifyBetween: <JustifyBetween />,
  JustifyCenter: <JustifyCenter />,
  JustifyEnd: <JustifyEnd />,
  JustifyEvenly: <JustifyEvenly />,
  JustifyStart: <JustifyStart />,
  SpaceBottom: <SpaceBottom />,
  SpaceTop: <SpaceTop />,
  SpaceLeft: <SpaceLeft />,
  SpaceRight: <SpaceRight />,
};
