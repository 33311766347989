import { ChevronDown } from "../../../../../../../assets/svg/ChevronDown";

import { TSelectButton } from "./SelectButton.types";

import "./SelectButton.css";

export const SelectButton: TSelectButton = ({ currentOption, options, onClick }) => {
  const valueFromOptions = options.find((option) => option.value === currentOption)?.label;
  const isRemove = currentOption === "";
  return (
    <button className={`select-button${isRemove ? " select-button--placeholder" : ""}`} onClick={onClick}>
      <span className="select-button-text">{valueFromOptions || "Auto"}</span>
      <ChevronDown className="select-button__end-icon" />
    </button>
  );
};
