import { useState } from "react";

import { ChevronDown } from "../../../../assets/svg/ChevronDown";

import "./Accordion.css";

export const Accordion = ({
  label,
  children,
  className = "",
  position = "right",
  startState = false,
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  position?: "right" | "left";
  startState?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState(startState);
  const handleClick = () => {
    setIsOpened((prev) => !prev);
  };
  return (
    <div className="accordion">
      <h2
        className={`accordion__heading${className ? ` ${className}` : ""}${
          position === "left" ? " accordion__heading--left" : ""
        }${isOpened ? " active" : ""}`}
        onClick={handleClick}
      >
        {position === "left" && <ChevronDown />}
        {label}
        {position === "right" && <ChevronDown />}
      </h2>
      <div className={`accordion__content${isOpened ? " active" : ""}`}>
        <div>{children}</div>
      </div>
    </div>
  );
};
