import { useEffect, useState } from "react";

import { styleManagerService } from "./StylesManager.service";
import { editorService } from "../editor";

import { Accordion } from "../../../../AlphaO/atoms/Accordion/Accordion";
import { NumberProperty } from "./components/NumberProperty";
import { SelectProperty } from "./components/SelectProperty";
import { RadioProperty } from "./components/RadioProperty";
import { CompositeProperty } from "./components/CompositeProperty";
import { OptionsDropdown } from "./components/OptionsDropdown";
import { ColorProperty } from "./components/ColorProperty/ColorProperty";
import { StackProperty } from "./components/StackProperty";

import { getSelectorFromActiveProject } from "../../../../AlphaO/molecules/FontVariables/helpers";

import { type PropertyStack, type PropertyComposite } from "grapesjs";
import { TStylesManager } from "./StylesManager.types";

import "./StylesManager.css";

export const StylesManager: TStylesManager = () => {
  const [state, subscribeStylesService] = useState(styleManagerService.state);
  const sectors = state.sectors;
  const usUnitModalVisible = styleManagerService.unitModalVisible;
  const editor = editorService.getEditor();

  const getStyle = (selector: string) => editor?.Css?.getRule(selector)?.attributes.style;
  const selectors = getSelectorFromActiveProject(editorService.getEditor()?.getCss() || "");
  const styleFromSelector = selectors?.length > 0 ? getStyle(selectors[0].selector) : {};
  const colorVariables = getStyle(".local-styles-variables");
  const fontVariables = getStyle(".local-text-styles-variables");

  const allVariables = {
    ...styleFromSelector,
    ...colorVariables,
    ...fontVariables,
  };

  useEffect(() => {
    styleManagerService.subscribe(subscribeStylesService);

    return () => {
      styleManagerService.unsubscribe(subscribeStylesService);
    };
  }, []);

  if (!sectors || Object.keys(sectors).length === 0) {
    return (
      <div className="styles-manager">
        <h2 className="styles-manager__header">Styles Manager</h2>
        <div style={{ padding: "1rem" }}>
          <p style={{ color: "#fff", font: "normal normal 400 14px/18px Inter", marginBottom: "16px" }}>
            You don't have any selected element.
          </p>
          <p style={{ color: "#fff", font: "normal normal 400 14px/18px Inter" }}>Select an element from the canvas.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="styles-manager custom-scrollbar">
      <h2 className="styles-manager__header">Styles Manager</h2>
      <ul className="styles-manager__sectors">
        {sectors?.map((sector) => {
          return (
            <Accordion key={sector.getId()} label={sector.getName()} startState={sector.isOpen()}>
              <div className="styles-manager__sector">
                {sector.getProperties().map((prop) => {
                  const requires = prop.get("requires");

                  // TODO refactor this
                  if (requires) {
                    const canRender = Object.keys(requires).every((key) => {
                      const requiredProperty = sector.getProperty(key);
                      // TODO refactor this, grapejs type is Record<string, any> | undefined
                      const requiresType = typeof requires[key];
                      if (requiresType !== "string" && !Array.isArray(requires[key])) {
                        console.error(`Invalid type of required property ${key} in ${prop.getLabel()}`);

                        return false;
                      }

                      if (Array.isArray(requires[key])) {
                        return requires[key].includes(requiredProperty?.getValue());
                      }

                      if (requiredProperty && requiredProperty.getValue() === requires[key]) return true;
                    });

                    if (!canRender) return null;
                  }

                  /* TODO change for property type => Component map */
                  if (prop.get("type") === "number") {
                    return <NumberProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
                  }

                  if (prop.get("type") === "select") {
                    return <SelectProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
                  }

                  if (prop.get("type") === "radio") {
                    // TODO move somewhere else??
                    const cssProperty = prop.get("property");
                    if (
                      cssProperty === "justify-content" ||
                      cssProperty === "align-items" ||
                      cssProperty === "align-content"
                    ) {
                      const direction = sector.getProperty("flex-direction")?.getValue();
                      const shouldRotate = direction === "column" || direction === "column-reverse";
                      return <RadioProperty property={prop} key={prop.getId()} rotateIcons={shouldRotate} />;
                    }
                    return <RadioProperty property={prop} key={prop.getId()} />;
                  }

                  if (prop.get("type") === "composite") {
                    return (
                      <CompositeProperty
                        property={prop as PropertyComposite}
                        key={prop.getId()}
                        allVariables={allVariables}
                      />
                    );
                  }

                  if (prop.get("type") === "color") {
                    return <ColorProperty property={prop} key={prop.getId()} allVariables={allVariables} />;
                  }

                  if (prop.get("type") === "stack") {
                    const stackProp = prop as PropertyStack;
                    return <StackProperty property={stackProp} key={prop.getId()} allVariables={allVariables} />;
                  }

                  return (
                    <span key={prop.getId()}>
                      {prop.getLabel()} - not supported type {prop.get("type")}
                    </span>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
      </ul>
      {usUnitModalVisible && <OptionsDropdown />}
    </div>
  );
};
