import { useEffect, useRef, useState } from "react";
import { Units } from "../Units";

import "./StyleManagerInput.css";

export const StyleManagerInput: TStyleManagerInput = ({
  type = "text",
  iconStart,
  handleChange,
  value,
  min,
  max,
  step,
  placeholder,
  unit,
  hasUnits,
  handleOpenUnitsDropdown,
  modifier,
  color,
  handleColorPicker,
}) => {
  const [localValue, setLocalValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    // grapejs change value on blur ot enter, so we need to handle it
    const handleKeyDown = (ev: KeyboardEvent) => {
      if (ev.key === "Enter") {
        handleChange(localValue.toString());
      }
    };
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("keydown", handleKeyDown);
      inputElement.addEventListener("blur", () => {
        inputElement.removeEventListener("keydown", handleKeyDown);
      });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [localValue]);

  const handleLocalChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(ev.target.value);
  };

  const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    if (localValue !== value) {
      handleChange(ev.target.value);
    }
  };

  const colorStyle =
    typeof value === "string" && value !== ""
      ? { backgroundColor: value }
      : {
          backgroundImage: `conic-gradient(var(--neutral-700) 0deg 90deg, var(--neutral-400) 90deg 180deg, var(--neutral-700) 180deg 270deg, var(--neutral-400) 270deg 360deg)`,
          backgroundSize: "67% 67%",
          opacity: 0.3,
        };

  return (
    <div
      className={`input-wrapper${modifier ? " input-wrapper--number" : ""}${
        value !== placeholder ? " input-wrapper--active" : ""
      }`}
    >
      {color && (
        <button className="input-wrapper__color-picker" style={colorStyle} onClick={handleColorPicker}></button>
      )}
      {iconStart && <div className="input-wrapper__icon-wrapper">{iconStart}</div>}
      <input
        ref={inputRef}
        type={type}
        placeholder={placeholder}
        value={localValue}
        min={min}
        max={max}
        step={step}
        onBlur={handleBlur}
        onChange={handleLocalChange}
      />
      {hasUnits || unit ? (
        <Units currentUnit={unit ? unit : "-"} onClick={hasUnits ? handleOpenUnitsDropdown : undefined} />
      ) : null}
    </div>
  );
};
