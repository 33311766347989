import { Component, Editor, Sectors, SelectOption } from "grapesjs";
import { Subscriber } from "../../../../../services/abstract/Subscriber/Subscriber";
import { editorService } from "../editor";

type StyleManagerServiceState = {
  sectors: Sectors | undefined;
  component: Component | undefined;
};

export class StyleManagerService extends Subscriber<StyleManagerServiceState> {
  state: StyleManagerServiceState = {
    sectors: undefined,
    component: undefined,
  };
  styleManager: Editor["StyleManager"] | undefined;
  // TODO move to own service??
  unitModalVisible = false;
  units: string[] | SelectOption[] = [];
  currentUnit = "";
  coordinates: DOMRect | null = null;
  onChange: ((unit: string) => void) | null = null;

  setComponent = (component: Component, sectors: Sectors) => {
    this.state.component = component;
    // TODO depend on component type exclude/include sectors
    this.state.sectors = sectors;
  };

  isOpen = () => this.unitModalVisible;

  setStyleManager = () => {
    this.styleManager = editorService.getEditor()?.StyleManager ?? undefined;
  };

  openUnitDropdown = (
    units: string[] | SelectOption[],
    currentUnit: string | undefined,
    coordinates: DOMRect,
    handleChange: (unit: string) => void
  ) => {
    this.unitModalVisible = true;
    this.units = units;
    this.currentUnit = currentUnit ?? "";
    this.coordinates = coordinates;
    this.onChange = handleChange;
    this.notifySubscribers();
  };

  closeUnitDropdown = () => {
    this.unitModalVisible = false;
    this.units = [];
    this.currentUnit = "";
    this.coordinates = null;
    this.onChange = null;
    this.notifySubscribers();
  };

  clear = () => {
    this.state = {
      sectors: undefined,
      component: undefined,
    };

    this.notifySubscribers();
  };
}

export const styleManagerService = new StyleManagerService();
